const decodeMeetingParams = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const meetingParams = urlParams.get("mp");
    // console.log("meetingParams: ", meetingParams);

    window.empaMeetingParams = JSON.parse(decodeURIComponent(meetingParams));
  } catch (error) {
    console.error(error);
  }
};

/**
 * get call information, can be used to verify if call exist & get custom information
 * @param {*} currentCall
 * @returns call information, throw error if call not exist
 */
const getMeetingInfo = async (currentCall /** stream Call instance */) => {
  return await currentCall?.get();
};

export { decodeMeetingParams, getMeetingInfo };
