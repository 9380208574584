import { Button } from "@nextui-org/react";
import "@stream-io/video-react-sdk/dist/css/styles.css";
import {
  CallingState,
  useCallStateHooks,
  SpeakerLayout,
  SpeakingWhileMutedNotification,
  ToggleAudioPublishingButton,
  CancelCallButton,
  ToggleVideoPublishingButton,
} from "@stream-io/video-react-sdk";
import { useCallback, useEffect, useState } from "react";
import { getMeetingInfo } from "../utils/meetingUtils";
import BigLogo from "../assets/images/logo-big.svg";
import PlayIcon from "../assets/images/play-icon.svg";
import VideoBg from "../assets/images/video-bg.png";

// const apiKey = "s8a8kb6zt7t6";
// const user = { id: "test-guest", name: "Guest", type: "guest" };

// const client = new StreamVideoClient({ apiKey, user });

const MeetingUi = ({ call }) => {
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();
  const [callInfo, setCallInfo] = useState();

  useEffect(() => {
    getMeetingInfo(call).then((resp) => {
      // console.log(`call info: `, resp);
      setCallInfo(resp);
    });
    // try {
    //   call?.join();
    // } catch (error) {
    //   console.error(error);
    // }
  }, [call]);

  const callingStateUI = useCallback(() => {
    switch (callingState) {
      case CallingState.UNKNOWN:
      case CallingState.IDLE:
      case CallingState.LEFT:
        return (
          <div
            className='w-full h-full flex justify-center'
            style={{
              background: "linear-gradient(180deg, #FFFFFF 0%, #F2F5F9 100%)",
            }}
          >
            <div className='w-full h-full flex flex-col justify-center px-9 max-w-[480px]'>
              {/* logo */}
              <img src={BigLogo} alt='' className='w-28' />
              {/* info card */}
              <div
                className='rounded-2xl bg-white p-4 mt-5 shadow-lg shadow-[#22242C27] bg-no-repeat'
                style={{
                  backgroundImage: `url(${VideoBg})`,
                  backgroundPosition: "right top",
                }}
              >
                <div className='flex items-center'>
                  <img src={PlayIcon} alt='' className='w-[22px] h-[22px]' />
                  <span className='font-semibold text-base text-[#5D6A85] ml-2'>
                    Empathia Online Consultation with
                  </span>
                </div>
                <div className='text-2xl font-bold text-[#242424] mt-4'>
                  {callInfo?.call?.created_by?.name}
                </div>
                <div className='pt-3 text-sm'>
                  <span className='text-[#9EA7B8]'>Join as: </span>
                  <span className='font-medium text-[#242424]'>
                    {callInfo?.call?.custom?.patientName}
                  </span>
                </div>
              </div>
              {/* join button */}
              <Button
                type='primary'
                className='w-full h-11 mt-6 text-white bg-[#4272F2] rounded-lg text-base font-semibold'
                onClick={() => call?.join()}
              >
                Join Now
              </Button>
            </div>
          </div>
        );

      case CallingState.RINGING:
        //   return <IncomingCallScreen />;
        return "Ringing";

      case CallingState.JOINING:
        //   return <LoadingCallScreen />;
        return (
          <div className='h-full w-full flex justify-center items-center'>
            Connecting...
          </div>
        );

      case CallingState.JOINED:
        return (
          <div className='h-full flex justify-between flex-col'>
            <SpeakerLayout />
            {/* <CallControls /> */}
            <div className='str-video__call-controls'>
              <SpeakingWhileMutedNotification>
                <ToggleAudioPublishingButton />
              </SpeakingWhileMutedNotification>
              <ToggleVideoPublishingButton />
              <CancelCallButton />
            </div>
          </div>
        );
      case CallingState.RECONNECTING:
      case CallingState.MIGRATING:
        return "Restoring";

      case CallingState.RECONNECTING_FAILED:
        return (
          <Button type='primary' onClick={() => call?.join()}>
            Rejoin
          </Button>
        );

      case CallingState.OFFLINE:
        return "Off line";

      default:
        const exhaustiveCheck = callingState;
        throw new Error(`Unknown calling state: ${exhaustiveCheck}`);
    }
  }, [callingState, call, callInfo]);

  return callingStateUI();

  //   return (
  //     <div className='bg-gray-200 w-full h-lvh'>
  //       {/* {callingStateUI()} */}
  //       {/* <StreamVideo client={client}>
  //         <StreamTheme>
  //           {call != null ? (
  //             callingState !== CallingState.LEFT ? (
  //               <StreamCall call={call}>
  //                 <PaginatedGridLayout />
  //                 <CallControls />
  //               </StreamCall>
  //             ) : null
  //           ) : null}
  //         </StreamTheme>
  //       </StreamVideo> */}
  //       {callingStateUI()}
  //     </div>
  //   );
};

export default MeetingUi;
